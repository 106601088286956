import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { map, of, switchMap, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { environment } from '../environments/environment';

@Component({
  standalone: true,
  imports: [],
  selector: 'qld-recreational-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'delete-account';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) {}

  public email = '';

  async ngOnInit() {
    console.log('AppComponent ngOnInit');

    this.authService
      .handleRedirectObservable()
      .pipe(
        map(
          (result) =>
            result?.account ||
            this.authService.instance.getActiveAccount() ||
            this.authService.instance.getAllAccounts()[0]
        ),
        switchMap((account) =>
          account ? of(account) : this.login().pipe(map(() => account))
        ),
        tap((account) => this.authService.instance.setActiveAccount(account))
      )
      .subscribe((result) => {
        this.email = result.username;

        const endpoint = new URL(environment.deleteAccountEndpoint);
        endpoint.searchParams.append('clientVersion', '123');
        endpoint.searchParams.append('requestID', uuid());

        this.http.get(endpoint.href).subscribe((res) => {
          //
        });
      });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      return this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      return this.authService.loginRedirect();
    }
  }
}
