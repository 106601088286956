export const environment = {
  scope:
    'openid offline_access https://itpauthtest.onmicrosoft.com/fishnettestapi/Client.ReadWrite',
  clientId: '24637c1b-0e1d-48f6-87be-5004f04bfc46',
  redirectUrl: 'http://localhost:4200/login',
  authority:
    'https://itpauthtest.b2clogin.com/itpauthtest.onmicrosoft.com/B2C_1_signin_fishnettest',
  knownAuthority: 'https://itpauthtest.b2clogin.com',
  deleteAccountEndpoint: `https://fishnettest.fisheries.qld.gov.au/api/rec/preferences`,
};
